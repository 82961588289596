import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import Form from './form';

const CCPA_FORM_ID = 4;

const Hero = ({ title, content, formTitle, formButtonText }) => (
  <section className="pb-[72px] header-offset">
    <Container>
      <div className="relative max-w-[1220px] mt-8 mx-auto grid grid-cols-1 lg:grid-cols-2 lg:gap-x-9">
        <div className="col-start-1 col-end-2 pt-8">
          <Heading className="tracking-wide" tag="h1" size="lg" fontWeight="extra">
            {title}
          </Heading>
          <div
            className="with-link-primary mt-8 text-xl grid grid-flow-row gap-y-6"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>

        <div className="lg:col-start-2 lg:mt-0 mt-12 col-span-1">
          <Form
            title={formTitle}
            formId={CCPA_FORM_ID}
            formName="ccpa-form"
            buttonText={formButtonText}
          />
        </div>
      </div>
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  formButtonText: PropTypes.string,
};

Hero.defaultProps = {
  formButtonText: '',
};

export default Hero;
