import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';

import EmailInputForm from 'components/shared/email-input-form';
import Heading from 'components/shared/heading';
import { gtagBookDemoSubmissionSuccessEvent } from 'constants/constants';
import MainContext from 'context/main';

import lineBottomLeft3Xl from './images/line-bottom-left-3xl.svg';
import lineBottomLeft from './images/line-bottom-left.svg';
import lineBottomRight3Xl from './images/line-bottom-right-3xl.svg';
import lineBottomRight from './images/line-bottom-right.svg';
import lineTopLeft from './images/line-top-left.svg';
import lineTopRight from './images/line-top-right.svg';
import logo from './images/logo.svg';

const FORM_ID = 2;
// Wrapper
const wrapperClassNames = classNames(
  /* base */ 'relative mt-16 overflow-hidden before:none before:left-0 before:top-0 before:w-1/2 before:h-full before:bg-secondary-4 before:-translate-x-48',
  'after:none after:right-0 after:top-0 after:w-1/2 after:h-full after:bg-black after:translate-x-48',
  /* xl-size */ 'xl:mt-60 xl:before:absolute xl:after:absolute',
  /* lg-size */ 'lg:mt-40',
  /* md-size */ 'md:mt-28'
);

// Title
const titleLeftClassNames = classNames(
  /* base */ 'uppercase text-center tracking-wide mark-color-black xxs:text-xxl sm:text-2xl',
  /* 2xl-size */ '2xl:text-5xl',
  /* xl-size */ 'xl:text-4xl'
);

const BookADemo = () => {
  const {
    sharedBlocks: {
      bookADemo: {
        acf: { leftTitle, leftFooterText, rightTitle, buttonText },
      },
    },
  } = useContext(MainContext);

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pages/home/book-a-demo/image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 402)
        }
      }
    }
  `);

  return (
    <section className={wrapperClassNames}>
      <div className="relative">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col items-center px-4 py-12 md:px-0 xl:py-20 bg-secondary-4">
            <Heading className={titleLeftClassNames} tag="h2" size="lg" asHTML>
              {leftTitle}
            </Heading>
            <div className="mt-8 xl:mt-10">
              <GatsbyImage className="w-[257px] xl:w-auto h-auto" image={getImage(image)} alt="" />
            </div>
            <div
              className="mt-5 text-xs text-center xl:mt-8 xl:text-base"
              dangerouslySetInnerHTML={{ __html: leftFooterText }}
            />
          </div>

          <div
            className="relative px-4 md:px-10 py-12 xl:py-20 bg-black z-10 min-h-[362px] lg:min-h-full xl:min-h-[566px]"
            id="book-a-demo-wrapper"
          >
            <div className="relative z-10">
              <Heading
                className="uppercase text-center tracking-wide max-w-[424px] lg:max-w-[670px] xl:max-w-[720px] mx-auto xxs:text-xxl sm:text-2xl"
                tag="h2"
                size="lg"
                theme="white"
              >
                {rightTitle}
              </Heading>
              <EmailInputForm
                className="mt-12 xl:mt-[4.375rem] max-w-[390px] xl:max-w-[602px] mx-auto book-demo-form"
                buttonText={buttonText}
                formName="book-a-demo"
                formId={FORM_ID}
                gtagSuccessEvent={gtagBookDemoSubmissionSuccessEvent}
                backgroundColorInput="yellow"
                withBorder
              />
            </div>
            <div>
              <img
                className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[362px] xl:w-auto"
                src={logo}
                height={566}
                width={566}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="absolute top-0 left-0 w-[40px] xl:w-auto"
                src={lineTopLeft}
                height={54}
                width={80}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="hidden md:block absolute top-0 right-0 xl:-right-6 2xl:right-0 w-[52px] xl:w-auto"
                src={lineTopRight}
                height={80}
                width={80}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="3xl:hidden absolute -bottom-20 md:bottom-0 left-0 w-[258px] md:w-[160px] xl:w-auto"
                src={lineBottomLeft}
                height={260}
                width={250}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="hidden 3xl:block absolute bottom-0 left-0 w-[160px] xl:w-auto"
                src={lineBottomLeft3Xl}
                height={260}
                width={280}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="hidden md:block 3xl:hidden absolute bottom-0 right-0 xl:-right-40 2xl:right-0 w-[303px] xl:w-auto"
                src={lineBottomRight}
                height={240}
                width={473}
                alt=""
                loading="lazy"
                aria-hidden
              />
              <img
                className="hidden 3xl:block absolute bottom-0 right-0 xl:-right-40 2xl:right-0 w-[303px] xl:w-auto"
                src={lineBottomRight3Xl}
                height={240}
                width={601}
                alt=""
                loading="lazy"
                aria-hidden
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookADemo;
